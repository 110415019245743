<template>
  <div class="chargeSetting">
    <navBar name="收费设置" :list="navList"></navBar>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navList: [
        {
          name: "收费设置",
          path: "/charge/chargesetting/chargeset",
        },
        {
          name: "日常收费设置",
          path: "/charge/chargesetting/daychargeset",
        },
        {
          name: "合同收费设置",
          path: "/charge/chargesetting/contractchargeset",
        },
        {
          name: "收费项设置(附属)",
          path: "/charge/chargesetting/assistantContractChargeset",
        },
      ],
    };
  },
};
</script>
<style lang="scss"></style>
